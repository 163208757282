<script>
export default {
  data() {
    return {
      devis: null,
      listitemrender: null,
      searchValue: "",
      filter: "all",
      modaldelete: false,
      devisScopedId: '',
      devisScopedTitle: '',
    };
  },
  created: async function () {
    await fetch(process.env.VUE_APP_BACKEND_URL +"/getAll" , { method: "GET" })
      .then((response) => response.json())
      .then((response) => {
        this.devis = response;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  computed: {
    filteredItems() {
      if(!this.devis) {
        return []
      }
      const sortedResponse = this.devis.sort(function(a, b) {
          if(!a.completed && b.completed){
            return -1;
          }
          if(a.completed && !b.completed){
            return 1;
          }
          return 0;
        });
      if(this.filter === "all"){
      return sortedResponse.filter(item => {
         return item.content.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1 || item.content.numdevis.value.toString().indexOf(this.searchValue.toLowerCase()) > -1
      })} else if(this.filter === "uncompleted") {
        return sortedResponse.filter(item => {
         return (item.content.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1 || item.content.numdevis.value.toString().indexOf(this.searchValue.toLowerCase()) > -1) && !item.completed 
      })} else if(this.filter === "completed") {
        return sortedResponse.filter(item => {
         return (item.content.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1 || item.content.numdevis.value.toString().indexOf(this.searchValue.toLowerCase()) > -1) && item.completed 
        })}
    }
  },
  methods: {
    search() {
      this.searchValue = this.$refs.search.value||"";
    },
    modalDelete(devisId, devisTitle){
      this.devisScopedId = devisId;
      this.devisScopedTitle = devisTitle;
      this.modaldelete = true;
    },
    async deleteDevis() {
      const data = await fetch(
        `${process.env.VUE_APP_BACKEND_URL + "/deletedevis/" + this.devisScopedId}`,
        {
          method: "DELETE",
        }
      )
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
      if (data) {
        await fetch(process.env.VUE_APP_BACKEND_URL + "/getAll", { method: "GET" })
          .then((response) => response.json())
          .then((response) => {
            this.devis = response;
            this.modaldelete = false;
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      }
    },
    async downloadPdf(devisId, index) {
      this.listitemrender = index;
        try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL + "/download/" + devisId}`);
        const data = await response.blob();
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${devisId + ".pdf"}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        this.listitemrender = null;
      } catch (e) {
        console.error(e);
      }        
    },
    async downloadTex(devisId, index) {
      this.listitemrender = index;
        try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL + "/downloadtex/" + devisId}`);
        const data = await response.blob();
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${devisId + ".tex"}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        this.listitemrender = null;
      } catch (e) {
        console.error(e);
      }        
    },
    async cloneDevis(content) {
      const oldData = {...content};
      oldData.title = oldData.title + ' copie';
      const data = await fetch(process.env.VUE_APP_BACKEND_URL + "/createdevis", {
					method: "POST",
					headers: {
						"Content-Type": "application/json;charset=utf-8",
					},
					body: JSON.stringify({
						devisContent: oldData,
					}),
				}).then((res) => {return res})
				.catch((err) => {
					console.error(err);
					return "error";
				});
        if (data) {
        await fetch(process.env.VUE_APP_BACKEND_URL + "/getAll", { method: "GET" })
          .then((response) => response.json())
          .then((response) => {
            this.devis = response;
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      }
    }
  },
};
</script>

<template>
  <div class="home">
    <h1>Liste des devis</h1>
  <div><fieldset class="filterfieldset">
          <label><input type="radio" name="all" value="all" checked v-model="filter" />
					Tous les devis</label>
          <label><input type="radio" name="uncompleted" value="uncompleted" v-model="filter" />
					En cours</label>
          <label><input type="radio" name="completed" value="completed" v-model="filter" />
					Terminés</label>
          </fieldset></div>
        <div class="searchbar"><label for="searchbar"><img src="../assets/glass.svg" alt="searchbar"/><input name="searchbar" type="text" @input="search" ref="search"/></label></div>
    <ul class="list">
      <li v-for="(item, index) in filteredItems" :key="index" :class="[item.completed ? 'completedli' : '']">
        <p>
          Devis n°{{item.content.numdevis.value }}: <b>{{ item.content.title }}</b>
        </p>
        <div class="buttondiv" v-if="listitemrender !== index">
          <router-link :to="`/devis/${item._id}`" v-if="!item.completed"
            ><button>MODIFIER</button></router-link
          >
          <button v-if="item.completed" @click="cloneDevis(item.content)">CLONER</button>
          <button v-if="item.completed" @click="downloadTex(item._id, index)">TEX</button>
          <button v-if="item.completed" @click="downloadPdf(item._id, index)">PDF</button>
          <button @click="modalDelete(item._id,item.content.title)">X</button>
        </div>
        <div v-if="listitemrender === index" class="dot-flashing"></div>
      </li>
    </ul>
  </div>

  <div id="modaldelete" v-if="modaldelete">
    <div class="modalblock">
      <span>Attention cette action supprimera le devis <b>{{ devisScopedTitle }}</b>, êtes-vous certain de vouloir poursuivre l'opération ?</span>
      <button @click="deleteDevis">Supprimer le devis</button>
      <button @click="()=> {modaldelete = false;devisScopedId='';devisScopedTitle=''}">Annuler</button>
      
    </div>
  </div>
  
<div class="pdfviewer">
<img src="../assets/phrase.png" alt="Rien ne vaut une vie... sauf devis">
</div>
</template>

<style>
#modaldelete {
  position: fixed;
  top: 0;
  left: 0;
  width:100vw;
  height:100vh;
  z-index: 10;
  background-color: #00000069;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalblock {
  z-index: 15;
  background-color: rgb(194, 242, 222);
  width:33%;
  height:33%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 10px;
  border: solid 4px #11a37b;
  padding:1rem;
}
.modalblock span{
  font-size: 2rem;
}
.modalblock button:first-of-type{
  background-color: rgb(202, 50, 108);
}
.modalblock button:first-of-type:hover{
  background-color: rgb(112, 31, 62);
}
.modalblock button{
  width: fit-content;
  margin: .5rem auto;
  padding: .5rem 1.5rem;
  font-size: 2rem;
  text-transform: uppercase;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;
  border-radius: 10px;
  color: white;
  background-color: rgb(50, 202, 151);
  border-color: rgb(21, 100, 67);
}
.modalblock button:hover {
  cursor: pointer;
  background-color: rgb(36, 112, 87);
}
.searchbar input{
    height: 45px;
    width: 87%;
    background-color: #00000000;
    border: none;
    font-size: x-large;
}
.searchbar input:focus-visible{
  outline: none;
}
.searchbar img{
  height:1.3rem;
  rotate: 90deg;
  margin-right: 1%;
}
.searchbar {
    margin: 1.5rem auto;
    border-radius: 10px;
    border: solid 2px #11a37b;
    width: 64%;
    font-size: x-large;
    text-align: left;
    padding-left: 2%;
}
.filterfieldset {
    padding: 20px;
    height: 20px;
    width: 60%;
    margin: 0 auto 1rem;
    border-radius: 10px;
    background-color: white;
    border: solid 2px #11a37b;
}
.filterfieldset label{
  text-transform: uppercase;
}
.filterfieldset input[type='radio']:checked:after {
        width: 13px;
        height: 13px;
        border-radius: 15px;
        position: relative;
        background-color: #11a37b;
        content: '';
        display: inline-block;
        visibility: visible;
    }
.pdfviewer{
  position:absolute;
  right:0;
  top:0;
  width:50vw;
  height:100vh;
  background-color: rgb(106, 228, 177);
}
.pdfviewer img{
padding-top: 30vh;
max-width:500px;
}
.home{
  overflow: auto;
  width:50vw;
  max-height:calc(100vh - 300px);

}
.list {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  margin-block: 0;
  padding-inline-start: 0;
}
.list li {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #5ec2a12f;
  border-radius: 10px;
  border: solid 2px #5ec2a1ab;
}
.list .completedli{
  background-color: #2f60c24f;
  border: solid 2px rgba(97, 130, 215, 0.671);
}
.completedli .buttondiv button{
  background-color: #5e79c2;
}
.completedli .buttondiv button:hover{
  background-color: #29479c;
}
.buttondiv {
  margin: auto 0;
  min-width: 140px;
  text-align: right;
}
.buttondiv button {
  height: 30px;
  background-color: #5ec2a1;
  border: none;
  color: white;
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
  border-radius: 5px;
  cursor: pointer;
}
.buttondiv button a:visited {
  color: white;
}
.buttondiv button:hover {
  background-color: #238363;
}
.dot-flashing {
  position: relative;
  margin: 1rem 2.5rem;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: rgba(128, 255, 219, 0.4);
  color: rgba(128, 255, 225, 0.4);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -30px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: rgba(128, 255, 217, 0.4);
  color: rgba(128, 255, 230, 0.4);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 30px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: rgba(128, 255, 225, 0.4);
  color: rgba(128, 255, 230, 0.4);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}
h1{
  text-transform: uppercase;
}
h1::before,
h1::after {
  display: block;
  content: "";
  width: 45%;
  margin:0 auto;
  border-bottom: solid 2px black;
}

@keyframes dot-flashing {
  0% {
    background-color: rgba(179, 255, 233, 0.4);
  }
  50%,
  100% {
    background-color: rgba(31, 197, 156, 0.4);
  }
}
</style>